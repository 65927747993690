import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { FieldModule } from '../field/field.module';
import { LibrariesModule } from '../libraries.module';
import { RenderComponentClassModule } from '../render-component-class/render-component-class.module';
import { WrapperAutocompleteModule } from '../wrapper-autocomplete/wrapper-autocomplete.module';
import { FormgenFieldAutocompleteComponent } from './field-autocomplete/formgen-field-autocomplete.component';
import { FormgenFieldCheckboxComponent } from './field-checkbox/formgen-field-checkbox.component';
import { FormgenFieldChipsComponent } from './field-chips/formgen-field-chips.component';
import { FormgenFieldControlComponent } from './field-control/formgen-field-control.component';
import { FormgenFieldDateComponent } from './field-date/formgen-field-date.component';
import { FormgenFieldMultiselectComponent } from './field-multiselect/formgen-field-multiselect.component';
import { FormgenFieldSelectComponent } from './field-select/formgen-field-select.component';
import { FormgenFieldTextComponent } from './field-text/formgen-field-text.component';
import { FormgenFieldTextareaComponent } from './field-textarea/formgen-field-textarea.component';
import { FormgenFieldComponent } from './field/formgen-field.component';
import { FormgenFormArrayComponent } from './form-array/formgen-form-array.component';
import { FormgenFormArrayDirective } from './form-array/formgen-form-array.directive';
import { FormgenFormComponent } from './form/formgen-form.component';
import { FormgenTemplateDirective } from './formgen-template.directive';
import { FormgenService } from './formgen.service';

@NgModule({
  imports: [
    AutoCompleteModule,
    CalendarModule,
    CheckboxModule,
    ChipsModule,
    CommonModule,
    DialogModule,
    FieldModule,
    FlexLayoutModule,
    LibrariesModule,
    MultiSelectModule,
    ReactiveFormsModule,
    RenderComponentClassModule,
    TooltipModule,
    TranslateModule,
    WrapperAutocompleteModule,
  ],
  declarations: [
    FormgenFieldAutocompleteComponent,
    FormgenFieldCheckboxComponent,
    FormgenFieldChipsComponent,
    FormgenFieldComponent,
    FormgenFieldControlComponent,
    FormgenFieldDateComponent,
    FormgenFieldSelectComponent,
    FormgenFieldMultiselectComponent,
    FormgenFieldTextComponent,
    FormgenFieldTextareaComponent,
    FormgenFormArrayComponent,
    FormgenFormArrayDirective,
    FormgenFormComponent,
    FormgenTemplateDirective,
  ],
  exports: [
    FormgenFieldAutocompleteComponent,
    FormgenFieldCheckboxComponent,
    FormgenFieldChipsComponent,
    FormgenFieldComponent,
    FormgenFieldControlComponent,
    FormgenFieldDateComponent,
    FormgenFieldMultiselectComponent,
    FormgenFieldSelectComponent,
    FormgenFieldTextComponent,
    FormgenFieldTextareaComponent,
    FormgenFormArrayComponent,
    FormgenFormArrayDirective,
    FormgenFormComponent,
    FormgenTemplateDirective,
  ]
})
export class FormgenModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: FormgenModule,
      providers: [
        FormgenService,
      ],
    };
  }
}
