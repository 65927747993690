import { Component, ElementRef, EventEmitter, Inject, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FineUploaderBasic, status } from 'fine-uploader/lib/core';
import * as _ from 'lodash';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { APP_CONST } from '../../app.const';
import { AuthenticationService } from '../auth/authentication.service';
import { TokenValidatorService } from '../http/token-validator.service';
import { EventService } from '../interaction/event.service';
import { SystemMessageService } from '../system-message/system-message.service';
import { ImgUploadDisplayComponent } from './display/img-upload-display.component';

@Component({
  selector: 'app-img-upload',
  exportAs: 'app-img-upload',
  templateUrl: 'img-upload.component.html',
  styleUrls: ['img-upload.component.scss']
})
export class ImgUploadComponent implements OnChanges, OnInit {
  @Input() autoUpload: boolean = true;
  @Input() endpoint: string = `${APP_CONST.API_FILE}/admin/file/upload-single`;
  @Input() serverFiles: IImgUploadItemServer[] = [];
  @Input() multiple: boolean = false;
  @Input() displayMainPictureViewer: boolean = true;
  @Input() isImageProductVariant: boolean = false;
  @Input() showActionButton: boolean = true;

  @Output() onDeleteSingle: EventEmitter<any> = new EventEmitter;
  @Output() onDeleted: EventEmitter<any> = new EventEmitter;
  @Output() onPrimaryChange: EventEmitter<any> = new EventEmitter;
  @Output() onError: EventEmitter<any> = new EventEmitter;
  @Output() onUploaded: EventEmitter<any> = new EventEmitter;
  @Output() onAllUploaded: EventEmitter<any> = new EventEmitter;

  @ViewChild('imageDisplay', { static: false }) elImageDisplay: ImgUploadDisplayComponent;

  uploader: FineUploaderBasic;

  mainFileId: number;
  primaryFileId: number;

  imagePlaceholder = (require('./img-upload-placeholder.png') as any).default;

  filesUploaded: any = [];
  fileIdsError: any = [];

  isUploading: boolean = false;

  contextMenus: MenuItem[];

  constructor(
    @Inject('GlobalEvent') private _event: EventService,
    @Inject('GlobalSystemMessage') private _globalSystemMessage: SystemMessageService,
    private _authentication: AuthenticationService,
    public _elementRef: ElementRef,
    private _ngZone: NgZone,
    private _tokenValidator: TokenValidatorService,
    private _translate: TranslateService,
  ) { }

  ngOnInit() {
    this.uploader = new FineUploaderBasic({
      autoUpload: this.autoUpload,
      debug: true,
      request: {
        customHeaders: {
          Authorization: `Bearer ${this._authentication.user.token}`,
        },
        endpoint: this.endpoint,
        inputName: 'file',
      },
      // cors: {
      //   expected: true,
      //   sendCredentials: true,
      // },
      deleteFile: {
        enabled: false,
      },
      callbacks: {
        onError: this.doOnError.bind(this),
        onAllComplete: this.doOnAllComplete.bind(this),
        onComplete: this.doOnComplete.bind(this),
        onStatusChange: this.doOnStatusChange.bind(this),
        onSubmit: this.doOnFileSubmit.bind(this),
        onSubmitted: this.doOnSubmitted.bind(this),
      }
    });

    this._event.listen('CORE:AUTHENTICATION:TOKENCHANGED').subscribe(event => {
      this.uploader.setCustomHeaders({
        Authorization: `Bearer ${event.data}`,
      });
    });

    if ((!_.isArray(this.serverFiles) && this.serverFiles) || (_.isArray(this.serverFiles) && this.serverFiles.length)) {
      this.uploader.addInitialFiles(this.transformServerFiles(this.serverFiles));

      this.filesUploaded = _.concat(this.filesUploaded, this.serverFiles);

      this.setMainImage(0);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'serverFiles') && !_.isArray(this.serverFiles) && this.serverFiles) {
      this._ngZone.run(() => {
        this.serverFiles = _.castArray(this.serverFiles);
      });
    }
  }

  transformServerFiles(files: IImgUploadItemServer[] = []): IImgUploadItemClient[] {
    return files.map((file, fileIdx) => {
      return {
        uuid: file.id,
        name: file.fileName,
        size: file.fileSize,
        thumbnailUrl: `${APP_CONST.API_FILE}/admin/file/${file.id}`,
      };
    });
  }

  trackByFile(index, file) {
    return file.id;
  }

  addFile(inputFile) {
    this.uploader.addFiles(inputFile);

    inputFile.value = '';

    if (this.autoUpload) {
      this.isUploading = true;
    }
  }

  doOnStatusChange(fileId: number, oldStatus: string, newStatus: string) {
    const uploads = this.getUploads();
    const uploadsLength = uploads.length;
    if (newStatus === status.DELETED && (this.mainFileId === fileId || uploadsLength === 0)) {
      if (uploadsLength > 0) {
        this.setMainImage(uploads[0].id);
      } else {
        this.resetMainImage();
      }
    }
  }

  doOnFileSubmit(data) {
    // return this._tokenValidator.validate().toPromise();
  }

  doOnComplete(fileId: number, fileName: string, responseJSON: any) {
    if (responseJSON.id) {
      this.onUploaded.emit(responseJSON);

      this.filesUploaded.push(responseJSON);
    }
  }

  doOnAllComplete(successFileIds: number[], failedFileIds: number[]) {
    this.isUploading = false;

    this.onAllUploaded.emit(this.filesUploaded);
  }

  doOnSubmitted(fileId: number) {
    if (this.getUploads().length === 1 || !this.multiple) {
      this.setMainImage(fileId);

      if (!this.multiple && fileId - 1 !== -1) {
        this.uploader.setStatus(fileId - 1, status.DELETED);
      }
    }
  }

  setInitialMainImages() {
    this.serverFiles.forEach((file, fileIdx) => {
      this.setMainImage(fileIdx);
    });
  }

  setMainImage(fileId: number) {
    this.mainFileId = fileId;

    if (this.displayMainPictureViewer) {
      const file = this.getFile(fileId);
      setTimeout(() => {
        this.uploader.drawThumbnail(fileId, this.elImageDisplay.elImage.nativeElement, 0, file.thumbnailUrl);
        this.elImageDisplay.elImage.nativeElement.className = 'img-full';
      }, 500);
    }
  }

  resetMainImage() {
    this.elImageDisplay.elImage.nativeElement.src = this.imagePlaceholder;
    this.elImageDisplay.elImage.nativeElement.className = 'img-fluid';
    this.mainFileId = undefined;
  }

  getUploads(): IImgUploadItemClient[] {
    return this.uploader.getUploads().filter(file => (
      file.status !== status.DELETED &&
      file.status !== status.REJECTED &&
      file.status !== status.CANCELED &&
      !this.fileIdsError.includes(file.id)
    ));
  }

  getFile(fileId: number) {
    const file = _.find(this.getUploads(), { id: fileId });

    if (file) {
      const serverFile = _.find(this.serverFiles, { id: file.uuid });
      if (serverFile) {
        file.thumbnailUrl = `${APP_CONST.API_FILE}/admin/file/${serverFile.id}`;
      }
    }

    return file;
  }

  upload(): Observable<any> {
    return Observable.create(observer => {
      this.isUploading = true;

      const successObs = this.onAllUploaded.subscribe((filesUploaded) => {
        observer.next(filesUploaded);
        observer.complete();
      });

      const errorObs = this.onError.subscribe(error => {
        if (_.has(error, '[3]')) {
          try {
            const jsonResponse = JSON.parse(error[3].response);
            if (jsonResponse.id) {
              return;
            }
          } catch (e) {

          }
        }
        observer.error(error);
      });

      this.uploader.uploadStoredFiles();

      return () => {
        this.isUploading = false;

        successObs.unsubscribe();
        errorObs.unsubscribe();
      };
    });
  }

  doDeleteSingle() {
    this.resetMainImage();
    this.onDeleteSingle.emit(true);
  }

  doDeleted(file: IImgUploadItemClient) {
    this.uploader.setStatus(file.id, status.DELETED);
    const fileUploaded = this.filesUploaded[file.id];
    this.onDeleted.emit(fileUploaded);
  }

  doPrimaryChange(file: IImgUploadItemClient) {
    const fileUploaded = this.filesUploaded[file.id];
    this.onPrimaryChange.emit(fileUploaded);
  }

  doOnError(fileId: number, name: string, errorReason: string, xhr: any) {
    if (!xhr.status.includes([200, 201])) {
      this._globalSystemMessage.log({
        message: this._translate.instant('error.image.upload'),
        type: 'error',
        showAs: 'growl',
        showSnackBar: false,
      });

      this.fileIdsError.push(fileId);

      setTimeout(() => {
        this.resetMainImage();

        const uploadedFiles = this.getUploads();
        if (uploadedFiles.length) {
          this.setMainImage(uploadedFiles[0].id);
        }
      }, 750);
    }
  }

  showContextMenu(event, cm) {
    this.prepCm(event);
    cm.show();
  }

  prepCm(file: IImgUploadItemClient) {
    this.contextMenus = [{
      label: this.isImageProductVariant ? this._translate.instant('ui.imgUpload.item.contextMenu.setAsPrimaryImageVariant') : this._translate.instant('ui.imgUpload.item.contextMenu.setAsPrimary'),
      command: () => this.doPrimaryChange(file),
    }, {
      label: this._translate.instant('ui.imgUpload.item.contextMenu.delete'),
      command: () => this.doDeleted(file),
    }];
  }
}
