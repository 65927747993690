import { Component, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { TenantChannelRestService } from '../../tenant-channel/tenant-channel-rest.service';
import { TenantChannelService } from '../../tenant-channel/tenant-channel.service';

@Component({
  selector: 'app-tenant-channel-event-message-history',
  templateUrl: 'tenant-channel-event-message-history.component.html'
})

export class TenantChannelEventMessageHistoryComponent extends BaseListBComponent<any> implements OnChanges {
  messageHistory: any = [];
  showedMessageHistory: any = [];
  pagination: any = {};
  pageNum: number = 1;
  tenantChannelId: string;
  _=_;

  constructor(
    private _tenantChannel: TenantChannelService,
    private _tenantChannelRest: TenantChannelRestService
  ) {
    super();
    this.componentId = 'TenantChannelEventMessageHistoryComponent';
    this.headerTitle = 'History Pesan';
    this.defaultWrapperTemplateClass = 'column';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'pageNum') && this.pageNum) {
      this.changePage();
    }
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: () => {
        return this._tenantChannelRest.getMessageHistory(this.tenantChannelId, this.qParams);
      },
      success: (data) => {
        // Reverse because the default order from woowa is desc refactor to backend later
        this.messageHistory = data.reverse().map((val, idx) => ({ seq: idx + 1, ...val}));

        this.showedMessageHistory = this.messageHistory.slice(0, 30);
        this.pagination = {
          totalItems: data.length,
          itemsPerPage: 30
        }
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true,
      wrapLoadingComponentIds: this.componentId
    });

    this.registerHook({
      hookName: 'init',
      handle: () => this.page.compHookService.callMultipleHooks([
        'TenantChannelEventMessageHistoryComponent:loadData',
      ]),
    });

    this.registerHook({
      hookName: 'resendMessage',
      handle: (hook) => {
        return this._tenantChannelRest.testSendMessage(this.tenantChannelId, {
          phoneNumberTarget: hook.data.receiverPhone,
          message: hook.data.message,
        });
      },
      success: () => {
        this.comp._globalSystemMessage.log({
          message: 'Pesan dikirim ulang',
          type: 'success',
          showAs: 'growl',
          showSnackBar: false,
        })
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true,
      wrapLoadingComponentIds: this.componentId
    });
  }

  appOnInit() {
    super.appOnInit();

    this.tenantChannelId = this.comp.routeParams.tenantChannelId;

    if (this._tenantChannel.currentTenantChannel) {
      this.headerTitle = `${this._tenantChannel.currentTenantChannel.channel.name} - ${this._tenantChannel.currentTenantChannel.name}`
      this.comp._changeDetectorRef.detectChanges();
    } else {
      this.comp._router.navigate(['/tenant-channel-event', this.tenantChannelId, 'configuration']);
    }

    return this.callHook('init');
  }

  changePage() {
    const from = (this.pageNum - 1) * this.pagination.itemsPerPage;
    const to = from + this.pagination.itemsPerPage;

    this.showedMessageHistory = this.messageHistory.slice(from, to);

    $('html,body').animate({ scrollTop: 0 }, 500);
  }

  resendMessage(messageId: string): void {
    const arrMessage = this.messageHistory
      .filter(message => message.messageId === messageId);

    if (arrMessage.length < 1) {
      return;
    }

    this.callHookDirectly('resendMessage', arrMessage[0]);
  }
}
