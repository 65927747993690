import { HttpClientModule } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConnectionBackend, HttpModule, XHRBackend } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { LayoutsModule } from './layouts/layouts.module';
import { TranslateLoaderService } from './libraries/common/translate-loader.service';
import { LibrariesModule } from './libraries/libraries.module';
import { PROVIDERS as TenantChannelEventProviders } from './main/tenant-channel-event/tenant-channel-event.module';
import { PROVIDERS as TenantChannelProviders } from './main/tenant-channel/tenant-channel.module';
export const REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<{}>>('Registered Reducers');
export const REDUCERS: ActionReducerMap<{}> = {};

export function HttpLoaderFactory(translatorLoaderService: TranslateLoaderService) {
  return {
    getTranslation(lang: string): Observable<Object> {
      return translatorLoaderService.load(lang);
    }
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AlertModule.forRoot(),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    HttpClientModule,
    HttpModule,
    LayoutsModule,
    LibrariesModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    PaginationModule.forRoot(),
    ReactiveFormsModule,
    StoreModule.forRoot(REDUCERS_TOKEN),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [TranslateLoaderService]
      }
    }),
  ],
  providers: [
    { provide: ConnectionBackend, useClass: XHRBackend },
    { provide: REDUCERS_TOKEN, useValue: REDUCERS },
    ...TenantChannelEventProviders,
    ...TenantChannelProviders,

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
