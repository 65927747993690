import { Injectable, TemplateRef } from '@angular/core';
import * as _ from 'lodash';
import { CommonService } from '../common/common.service';
import { MFormValidationFailed } from '../common/form.model';
import { TranslateService } from '@ngx-translate/core';
import { AclService } from '../auth/acl.service';

@Injectable()
export class MessageParserService {
  constructor(
    private _translateService: TranslateService,
    private _acl: AclService,

  ) { }

  parse(messages: any) {
    let parsedMessages: (string | TemplateRef<any>)[] = [];

    if (_.isArray(messages)) {
      messages.forEach(messageSet => this.parseMessageObject(messageSet, parsedMessages));
    } else if (_.isObject(messages)) {
      this.parseMessageObject(messages, parsedMessages);
    } else if (_.isString(messages)) {
      parsedMessages.push(messages);
    }

    if (!parsedMessages.length) {
      parsedMessages = parsedMessages.concat(this.populateDefaultMessages(messages));
    }

    return parsedMessages.filter(_.identity);
  }

  parseSpesific(response: any) {
    if (_.has(response, 'data.errors') && _.has(response, 'data.data')) {
      _.set(response.data, 'summary', {});

      return this.handleErrors(response);
    }
  }

  private handleErrors(response: any) {
    const errorMessages = [];

    _.forEach(response.data.errors, error => {
      const errorMessage = _.get(error, 'error_message');
      const errorCode = error.error_code;
      if (errorMessage || errorCode) {
        if (errorMessage && errorCode) {
          errorMessages.push(`${errorMessage} [${errorCode}]`);
        } else if (errorMessage && !errorCode) {
          errorMessages.push(errorMessage);
        } else if (!errorMessage && errorCode) {
          errorMessages.push(`Error code ${errorCode}`);
        }

        if (errorCode) {
          _.set(response.data, `summary.${error.error_code}`, true);
        }
      }
    });

    return errorMessages;
  }

  parseFormValidationsFailedMessage(validationFails: MFormValidationFailed[], prefix: string = null) {
    const prefixer: string = 'importFormValidationErrors';
    const messages: {
      message: string;
      interpolation: MFormValidationFailed;
    }[] = [];

    _.forEach(validationFails, validationFail => {
      const message: string[] = [];
      message.push(prefix);
      message.push(prefixer);
      message.push(validationFail.pureControlName);
      message.push(validationFail.errorName);

      const messageString = CommonService.joinToString(message);
      messages.push({
        message: messageString,
        interpolation: validationFail,
      });
    });

    return messages;
  }

  private parseMessageObject(messageSet: any, parsedMessages: (string | TemplateRef<any>)[]) {
  
    if (messageSet instanceof TemplateRef) {
      parsedMessages.push(messageSet);
    } else if (_.isString(messageSet) && messageSet) {
      parsedMessages.push(messageSet);
    } else if (_.has(messageSet, 'error_essage') && messageSet.errorMessage) {
      parsedMessages.push(messageSet.errorMessage);
    } else if (_.has(messageSet, 'errors')) {
      _.forEach(messageSet.errors, errorMessage => {
        parsedMessages.push(errorMessage);
      });
    } else if (_.has(messageSet, 'data')) {
      _.forEach(messageSet.data, message => this.parseMessageObject(message, parsedMessages));
    }
  }

  private populateDefaultMessages(messages: any): string[] {
    let url: string;
    let statusCode: number;
    if (_.has(messages, 'request') && _.has(messages, 'response')) {
      url = _.get(messages, 'config.url');
      statusCode = _.get(messages, 'response.status');
      if (!statusCode) {
        return this._translateService.instant('error.http.noInternetConnection');
      }

      return this.setPopulateDefaultMessages(statusCode, url);
    }
    if (_.isArray(messages)) {

      _.forEach(messages, function(message) {
        url = _.get(message, 'config.url');
        statusCode = _.get(message, 'status') || _.get(message, 'response.status');
      });
      if (!statusCode) {
        return this._translateService.instant('error.http.noInternetConnection');
      }

      return this.setPopulateDefaultMessages(statusCode, url);

    }
  }

  private setPopulateDefaultMessages(statusCode: number, url: string) {
    switch (statusCode) {
      case 401:
      case 403:
      case 404:
      case 405:
      case 408:
      case 500:
      case 502:
      case 503:
        return this._translateService.instant(`error.httpCode.${statusCode}`, { url });
        break;
    }
  }
}
