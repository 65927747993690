import { Component } from '@angular/core';
import * as _ from 'lodash';
import { TenantChannelRestService } from '../tenant-channel-rest.service';
import { BaseUpdateBComponent } from '../../../libraries/base/update/base-update.bcomponent';
import { TenantChannelService } from '../tenant-channel.service';

@Component({
  selector: 'app-tenant-channel-update',
  templateUrl: 'tenant-channel-update.component.html'
})
export class TenantChannelUpdateComponent extends BaseUpdateBComponent {
  constructor(
    private _tenantChannel: TenantChannelService,
    private _tenantChannelRest: TenantChannelRestService,
  ) {
    super();
    this.componentId = 'TenantChannelUpdateComponent';
    this.routeURL = '/tenantChannels';
    this.entrySuccessI18n = 'Channel berhasil diupdate';
    this.headerTitle = 'Update Channel';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'load',
      handle: () => {
        return this._tenantChannelRest.load(this.comp.routeParams.id);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true,
    });

    this.registerHook({
      hookName: 'save',
      handle: () => {
        let formValue = this._tenantChannel.normalizeDoc(this.form.value);

        if (formValue.typeApp === 'mobile') {
          formValue = {
            title: formValue.title,
            imageId: formValue.imageId,
            sequence: formValue.sequence,
            typeApp: formValue.typeApp,
            link: formValue.link
          }
        }

        return this._tenantChannelRest.update(this.comp.routeParams.id, formValue);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true,
    });

    this.registerHook({
      hookName: 'init',
      handle: () => this.page.compHookService.callMultipleHooks([
        'TenantChannelFormComponent:workflowBuildForm',
        'TenantChannelUpdateComponent:workflowLoadData',
      ]),
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
