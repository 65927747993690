import { Component } from '@angular/core';
import swal from 'sweetalert2';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { TenantChannelEventRestService } from '../tenant-channel-event-rest.service';
import { TenantChannelService } from '../../tenant-channel/tenant-channel.service';

@Component({
  selector: 'app-tenant-channel-event-list',
  templateUrl: 'tenant-channel-event-list.component.html'
})

export class TenantChannelEventListComponent extends BaseListBComponent<any> {
  tenantChannelEvents: any = [];
  tenantChannelEventEnabled: any = {};
  tenantChannelId: string;

  constructor(
    private _tenantChannelEventRest: TenantChannelEventRestService,
    private _tenantChannel: TenantChannelService
  ) {
    super();
    this.componentId = 'TenantChannelEventListComponent';
    this.defaultWrapperTemplateClass = 'column';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: () => {
        return this._tenantChannelEventRest.findAll(this.tenantChannelId, this.qParams);
      },
      success: (data) => {
        for (const tenantChannel of data) {
          this.tenantChannelEventEnabled[tenantChannel._id] = tenantChannel.enabled;
        }

        this.tenantChannelEvents = data;
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true,
      wrapLoadingComponentIds: this.componentId
    });

    this.registerHook({
      hookName: 'toggleEnabled',
      handle: (event) => {
        const tenantChannelEventId: string = event.data;
        const enabled: boolean = !this.tenantChannelEventEnabled[tenantChannelEventId];

        return this._tenantChannelEventRest.toggleEnabled(tenantChannelEventId, enabled);
      },
      success: (data) => {
        const message: string = data.enabled ? 'Event telah berhasil diaktifkan' : 'Event telah berhasil dinonaktifkan';

        this.comp._globalSystemMessage.log({
          message,
          type: 'success',
          showAs: 'growl',
          showSnackBar: false
        });

        this.tenantChannelEventEnabled[data._id] = data.enabled;
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true,
      wrapLoadingComponentIds: this.componentId
    });

    this.registerHook({
      hookName: 'init',
      handle: () => this.page.compHookService.callMultipleHooks([
        'TenantChannelEventListComponent:loadData',
      ]),
    });
  }

  appOnInit() {
    super.appOnInit();

    this.tenantChannelId = this.comp.routeParams.tenantChannelId;

    if (this._tenantChannel.currentTenantChannel) {
      this.headerTitle = `${this._tenantChannel.currentTenantChannel.channel.name} - ${this._tenantChannel.currentTenantChannel.name}`
      this.comp._changeDetectorRef.detectChanges();
    } else {
      this.comp._router.navigate(['/tenant-channel-event', this.tenantChannelId, 'configuration']);
    }

    return this.callHook('init');
  }

  toggleEnabled(tenantChannelEventId: string) {
    const setActive: boolean = this.tenantChannelEventEnabled[tenantChannelEventId];
    this.tenantChannelEventEnabled[tenantChannelEventId] = !this.tenantChannelEventEnabled[tenantChannelEventId];

    return swal.fire({
      title: setActive ? 'Aktifkan event' : 'Nonaktifkan event',
      text: `Anda yakin untuk ${setActive ? 'mengaktifkan' : 'menonaktifkan'} event ini?`,
      icon: 'question',
      showCancelButton: true,
    }).then(res => {
      if (res.value) {
        return this.callHookDirectly('toggleEnabled', tenantChannelEventId);
      }
    });
  }
}
