import { Component } from '@angular/core';
import { BaseUpdateBComponent } from '../../../libraries/base/update/base-update.bcomponent';
import { TenantChannelEventRestService } from '../tenant-channel-event-rest.service';

@Component({
  selector: 'app-tenant-channel-event-update',
  templateUrl: 'tenant-channel-event-update.component.html'
})
export class TenantChannelEventUpdateComponent extends BaseUpdateBComponent {
  constructor(
    private _tenantChannelEventRest: TenantChannelEventRestService,
  ) {
    super();
    this.componentId = 'TenantChannelEventUpdateComponent';
    this.routeURL = '/tenant-channel-event';
    this.entrySuccessI18n = 'Template pesan berhasil diubah';
    this.defaultWrapperTemplateClass =' column';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'load',
      handle: () => {
        return this._tenantChannelEventRest.loadMessageTemplate(this.comp.routeParams.id);
      },
      success: (doc) => {
        this.headerTitle = `Update template pesan: ${this.comp._translate.instant('ui.tenantChannelEvent.enum.eventCode.' + doc.eventCode )}`;
        this.comp._changeDetectorRef.detectChanges();
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true,
    });

    this.registerHook({
      hookName: 'save',
      handle: () => {
        const formValue = this.form.value;

        return this._tenantChannelEventRest.update(this.comp.routeParams.id, formValue);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true,
    });

    this.registerHook({
      hookName: 'init',
      handle: () => this.page.compHookService.callMultipleHooks([
        'TenantChannelEventFormComponent:workflowBuildForm',
        'TenantChannelEventFormComponent:workflowLoadRelated',
        'TenantChannelEventUpdateComponent:workflowLoadData',
      ]),
    });
  }

  defineBaseRouteURL() {
    const baseRouteURL = `/tenant-channel-event/${this.comp.routeParams.tenantChannelId}/events`;

    this.cancelURL = baseRouteURL;
    this.listURL = baseRouteURL;
    this.comp._changeDetectorRef.detectChanges();
  }
  

  appOnInit() {
    super.appOnInit();

    this.defineBaseRouteURL()

    return this.callHook('init');
  }
}
