import { Component, OnInit } from '@angular/core';
import * as Domurl from 'domurl';
import * as _ from 'lodash';
import { AuthenticationService } from './libraries/auth/authentication.service';
import { UrlService } from './libraries/common/url.service';
import { ApiBootstrapService } from './libraries/http/api-bootstrap.service';

@Component({
  selector: 'clodeo-notif-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private _apiBootstrap: ApiBootstrapService,
    private _authentication: AuthenticationService,
    private _url: UrlService,
  ) { }

  ngOnInit() {
    const { urlOrigin, urlParams, urlPath } = this._url.extractHashParts();

    // replace token with original
    const urlApiKey = window.location.search.replace('?token=', '');
    if (urlApiKey) {
      urlParams.token = urlApiKey;
    }

    if (urlPath !== '/boot') {
      const redirectUrl = new Domurl(`${urlOrigin}/boot`);
      redirectUrl.query.sourcePath = urlPath;
      if (_.size(urlParams)) {
        redirectUrl.query.sourceQuery = urlParams;
      }

      window.location.href = `${urlOrigin}/#${redirectUrl.path}?${this._url.objectToQueryString(redirectUrl.query, false)}`;
    } else {
      // REFACTORABLE
      const assignedApiKey = window.location.href.split('token=')[1];
      if (assignedApiKey) {
        (this._authentication.user as any) = {};
        this._authentication.user.token = assignedApiKey;
      }

      this._apiBootstrap.removePreloader();
    }
  }
}
