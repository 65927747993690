import { Component } from '@angular/core';
import * as _ from 'lodash';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { TenantChannelRestService } from '../../tenant-channel/tenant-channel-rest.service';
import { TenantChannelService } from '../../tenant-channel/tenant-channel.service';

@Component({
  selector: 'app-tenant-channel-event-log-webhook',
  templateUrl: 'tenant-channel-event-log-webhook.component.html'
})
export class TenantChannelEventLogWebhookComponent extends BaseListBComponent<any> {
  rawLogWebhooks: any[] = [];
  logWebhooks: any[] = [];
  tenantChannelId: string;
  _ = _;
  readonly dateFilters: {
    currentDate: string,
    yesterday: string,
    currentWeek: string,
    currentMonth: string,
    lastMonth: string,
  } = {
    currentDate: 'currentDate',
    yesterday: 'yesterday',
    currentWeek: 'currentWeek',
    currentMonth: 'currentMonth',
    lastMonth: 'lastMonth',
  };
  readonly now: Date = new Date();
  dateFilterActive: string | null = null;
  minDate: string;
  selectedDate: string | null = null;

  constructor(
    private _tenantChannel: TenantChannelService,
    private _tenantChannelRest: TenantChannelRestService
  ) {
    super();
    this.componentId = 'TenantChannelEventLogWebhookComponent';
    this.headerTitle = 'Log Webhook';
    this.defaultWrapperTemplateClass = 'column';
    this.minDate = `${this.now.getFullYear()}-${String(this.now.getMonth() - 1).padStart(2, '0')}-01`;
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: () => {
        return this._tenantChannelRest.getLogWebhooks(this.tenantChannelId);
      },
      success: data => {
        this.rawLogWebhooks = data.map((logWebhook, idx) => ({
          seq: idx + 1,
          ...logWebhook
        }));

        this.handleFilterByCurrentDate();
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true,
      wrapLoadingComponentIds: this.componentId
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService.callMultipleHooks([
          'TenantChannelEventLogWebhookComponent:loadData'
        ])
    });
  }

  appOnInit() {
    super.appOnInit();

    this.tenantChannelId = this.comp.routeParams.tenantChannelId;

    if (this._tenantChannel.currentTenantChannel) {
      this.headerTitle = `${this._tenantChannel.currentTenantChannel.channel.name} - ${this._tenantChannel.currentTenantChannel.name}`;
      this.comp._changeDetectorRef.detectChanges();
    } else {
      this.comp._router.navigate([
        '/tenant-channel-event',
        this.tenantChannelId,
        'configuration'
      ]);
    }

    return this.callHook('init');
  }

  handleFilterByCurrentDate() {
    this.dateFilterActive = this.dateFilters.currentDate;
    this.selectedDate = `${this.now.getFullYear()}-${String(this.now.getMonth() + 1).padStart(2, '0')}-${this.now.getDate()}`;
    this.logWebhooks = this.rawLogWebhooks.filter((logWebhook) => {
      const createdAt = new Date(logWebhook.createdAt);

      return createdAt.getFullYear() === this.now.getFullYear()
        && createdAt.getMonth() === this.now.getMonth()
        && createdAt.getDate() === this.now.getDate();
    });
  }

  handleFilterByYesterday() {
    this.dateFilterActive = this.dateFilters.yesterday;
    this.selectedDate = null;
    this.logWebhooks = this.rawLogWebhooks.filter((logWebhook) => {
      const createdAt = new Date(logWebhook.createdAt);

      return createdAt.getFullYear() === this.now.getFullYear()
        && createdAt.getMonth() === this.now.getMonth()
        && createdAt.getDate() === this.now.getDate() - 1;
    });
  }

  handleFilterByCurrentWeek() {
    this.dateFilterActive = this.dateFilters.currentWeek;
    this.selectedDate = null;
    this.logWebhooks = this.rawLogWebhooks.filter((logWebhook) => {
      const createdAt = new Date(logWebhook.createdAt);

      return createdAt.getFullYear() === this.now.getFullYear()
        && createdAt.getMonth() === this.now.getMonth()
        && createdAt.getDate() >= this.now.getDate() - 7;
    });
  }

  handleFilterByCurrentMonth() {
    this.dateFilterActive = this.dateFilters.currentMonth;
    this.selectedDate = null;
    this.logWebhooks = this.rawLogWebhooks.filter((logWebhook) => {
      const createdAt = new Date(logWebhook.createdAt);

      return createdAt.getFullYear() === this.now.getFullYear()
        && createdAt.getMonth() === this.now.getMonth();
    });
  }

  handleFilterByLastMonth() {
    this.dateFilterActive = this.dateFilters.lastMonth;
    this.selectedDate = null;
    this.logWebhooks = this.rawLogWebhooks.filter((logWebhook) => {
      const createdAt = new Date(logWebhook.createdAt);

      return createdAt.getFullYear() === this.now.getFullYear()
        && createdAt.getMonth() === this.now.getMonth() - 1;
    });
  }

  handleFilterByDate() {
    const [year, month, date]: string[] = this.selectedDate.split('-');

    this.dateFilterActive = null;
    this.logWebhooks = this.rawLogWebhooks.filter((logWebhook) => {
      const createdAt = new Date(logWebhook.createdAt);

      return createdAt.getFullYear() === Number(year)
        && createdAt.getMonth() === (Number(month) - 1)
        && createdAt.getDate() === Number(date);
    });
  }
}
