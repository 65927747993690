import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Error403Component } from './403/403.component';
import { Error404Component } from './404/404.component';

export const routes: Routes = [{
  path: '',
  children: [{
    path: '403',
    component: Error403Component
  }, {
    path: '404',
    component: Error404Component
  }]
}];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class ErrorRoutingModule { }
