import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseFormBComponent } from '../../../libraries/base/form/base-form.bcomponent';
import { TenantChannelEventRestService } from '../tenant-channel-event-rest.service';

@Component({
  selector: 'app-tenant-channel-event-form',
  templateUrl: 'tenant-channel-event-form.component.html'
})

export class TenantChannelEventFormComponent extends BaseFormBComponent {
  constructor(
    private _tenantChannelEventRest: TenantChannelEventRestService
  ) {
    super();

    this.componentId = 'TenantChannelEventFormComponent';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'buildForm',
      handle: () => {
        this.formgenSchema.fields = [{
          label: 'Template pesan',
          name: 'messageTemplate',
          field: 'textarea',
          templateConfig: {
            textarea: {
              rows: 25
            }
          },
          validations: {
            required: true,
          },
        }, {
          label: 'Kirim ke',
          name: 'recipientType',
          field: 'select',
          defaultValue: 'customer',
          templateConfig: {
            select: {
              options: [{
                i18nLabel: 'Kirim pesan ke customer',
                value: 'customer'
              }, {
                i18nLabel: 'Kirim pesan ke internal',
                value: 'tenant'
              }, {
                i18nLabel: 'Kirim pesan ke group',
                value: 'group'
              }]
            }
          },
          controlEvents: {
            valueChanges: (val: string, form: FormGroup) => {
              form.get('recipientTarget').reset();
              if (val === 'group') {
                form.get('recipientTarget').setValidators([Validators.required]);
                form.get('recipientTarget').updateValueAndValidity();
              } else {
                form.get('recipientTarget').setValidators([]);
                form.get('recipientTarget').updateValueAndValidity();
              }
            }
          }
        }, {
          name: 'recipientTarget',
          field: 'text',
          templateConfig: {
            wrapFormGroupClass: false
          }
        }];
      }
    });

    this.registerHook({
      hookName: 'loadRelated',
      handle: () => {
        return this._tenantChannelEventRest.loadMessageTemplateCodes().pipe(switchMap(templates => {
          const relatedData: any = {};
          relatedData.templates = templates;

          return of(relatedData);
        }));
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true,
    });
  }
}
