import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationService } from '../../libraries/auth/authorization.service';
import { BasePageComponent } from '../../libraries/base/page/base-page.component';
import { TenantChannelListComponent } from './list/tenant-channel-list.component';
import { TenantChannelCreateComponent } from './create/tenant-channel-create.component';
import { TenantChannelUpdateComponent } from './update/tenant-channel-update.component';

export const routes: Routes = [{
    path: '',
    children: [{
        path: '',
        component: BasePageComponent,
        children: [{
            path: '',
            component: TenantChannelListComponent,
            // canActivate: [AuthorizationService],
            data: {
                name: 'tenantChannel.read'
            },
        }],
    }, {
        path: 'create',
        component: BasePageComponent,
        children: [{
            path: '',
            component: TenantChannelCreateComponent,
            // canActivate: [AuthorizationService],
            data: {
                name: 'tenantChannel.create'
            },
        }],
        }, {
            path: ':id/update',
            component: BasePageComponent,
            children: [{
                path: '',
                component: TenantChannelUpdateComponent,
                // canActivate: [AuthorizationService],
                data: {
                    name: 'tenantChannel.update'
                },
            }],
        }]
}];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})

export class TenantChannelRoutingModule { }
