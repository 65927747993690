import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { zip } from 'rxjs';
import { BaseCompBComponent } from '../../../libraries/base/comp/base-comp.bcomponent';
import { CommonService } from '../../../libraries/common/common.service';
import { TenantChannelRestService } from '../../tenant-channel/tenant-channel-rest.service';
import { TenantChannelService } from '../../tenant-channel/tenant-channel.service';

@Component({
  selector: 'app-tenant-channel-event-configuration',
  templateUrl: 'tenant-channel-event-configuration.component.html'
})

export class TenantChannelEventConfigurationComponent extends BaseCompBComponent {
  doc: any = {};
  sendMessageForm: FormGroup = new FormGroup({});
  updateForm: FormGroup = new FormGroup({});
  tenantChannelId: string;
  editMode: boolean = false;

  constructor(
    private _tenantChannel: TenantChannelService,
    private _tenantChannelRest: TenantChannelRestService
  ) {
    super();
    this.componentId = 'TenantChannelEventConfigurationComponent';
    this.headerTitle = 'Pengaturan Channel';
    this.defaultWrapperTemplateClass = 'column';

    this.buildSendMessaggeForm();
    this.buildUpdateForm();
  }

  buildSendMessaggeForm() {
    this.sendMessageForm.addControl('phoneNumberTarget', new FormControl('', [Validators.required]));
    this.sendMessageForm.addControl('message', new FormControl('', [Validators.required]));
  }

  buildUpdateForm() {
    this.updateForm.addControl('name', new FormControl('', [Validators.required]));
    this.updateForm.addControl('license', new FormControl('', [Validators.required]));
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: () => {
        return this._tenantChannelRest.load(this.tenantChannelId);
      },
      success: (data) => {
        this.updateDoc(data);
        this.comp._changeDetectorRef.detectChanges();
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true,
      wrapLoadingComponentIds: this.componentId
    });

    this.registerHook({
      hookName: 'testConnection',
      handle: () => {
        return this._tenantChannelRest.testConnection(this.tenantChannelId);
      },
      success: (connectionStatus) => {
        // Disable growl on first time load
        if (this.doc.connectionStatus) {
          this.comp._globalSystemMessage.log({
            message: 'Tes koneksi berhasil',
            type: 'success',
            showAs: 'growl',
            showSnackBar: false,
          })
        }

        this.doc.connectionStatus = connectionStatus.status;
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true,
      wrapLoadingComponentIds: this.componentId
    });

    this.registerHook({
      hookName: 'testSendMessage',
      handle: () => {
        return this._tenantChannelRest.testSendMessage(this.tenantChannelId, this.sendMessageForm.value);
      },
      success: () => {
        this.comp._globalSystemMessage.log({
          message: 'Pesan berhasil dikirim',
          type: 'success',
          showAs: 'growl',
          showSnackBar: false,
        })
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true,
      wrapLoadingComponentIds: this.componentId
    });

    this.registerHook({
      hookName: 'updateTenantChannel',
      handle: () => {
        const formValue = this._tenantChannel.normalizeDoc(this.updateForm.value);

        return this._tenantChannelRest.update(this.tenantChannelId, formValue);
      },
      success: (doc) => {
        this.comp._globalSystemMessage.log({
          message: 'Tenant berhasil diupdate',
          type: 'success',
          showAs: 'growl',
          showSnackBar: false,
        })

        this.updateDoc(doc);
        // Temporary
        this.doc.connectionStatus = 'phone_online';
        this.editMode = false;

        this.comp._changeDetectorRef.detectChanges();
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true,
      wrapLoadingComponentIds: this.componentId
    });

    this.registerHook({
      hookName: 'init',
      handle: () => this.page.compHookService.callMultipleHooks([
        'TenantChannelEventConfigurationComponent:loadData',
        'TenantChannelEventConfigurationComponent:testConnection',
      ]),
    });
  }

  appOnInit() {
    super.appOnInit();

    this.tenantChannelId = this.comp.routeParams.tenantChannelId;

    return this.callHook('init');
  }

  updateDoc(data) {
    this.doc = data;
    this._tenantChannel.currentTenantChannel = data;

    this.updateForm.patchValue({
      name: data.name,
      license: data.credential.license,
    })
    this.headerTitle = `${data.channel.name} - ${data.name}`;
  }

  testConnection() {
    this.callHookDirectly('testConnection');
  }

  sendMessage() {
    if (this.formValid('sendMessageForm')) {
      this.callHookDirectly('testSendMessage');
    }
  }

  updateTenantChannel() {
    if (this.formValid('updateForm')) {
      this.callHookDirectly('updateTenantChannel');
    }
  }

  formValid(formVarName: string): boolean {
    if (!this[formVarName].valid) {
      CommonService.markAsDirty(this[formVarName]);

      this.comp._systemMessage.log({
        message: this.comp._translate.instant('error.form'),
        type: 'error',
      });

      return false;
    }
    return true;
  }
}
