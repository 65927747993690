import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { FormgenModule } from '../../libraries/formgen/formgen.module';
import { LibrariesModule } from '../../libraries/libraries.module';
import { SharedModule } from '../../libraries/shared.module';
import { TenantChannelEventConfigurationComponent } from './configuration/tenant-channel-event-configuration.component';
import { TenantChannelEventFormComponent } from './form/tenant-channel-event-form.component';
import { TenantChannelEventListComponent } from './list/tenant-channel-event-list.component';
import { TenantChannelEventLogWebhookComponent } from './log-webhook/tenant-channel-event-log-webhook.component';
import { TenantChannelEventMessageHistoryComponent } from './message-history/tenant-channel-event-message-history.component';
import { TenantChannelEventTabComponent } from './tab/tenant-channel-event-tab.component';
import { TenantChannelEventRestService } from './tenant-channel-event-rest.service';
import { TenantChannelEventRoutingModule } from './tenant-channel-event-routing.module';
import { TenantChannelEventUpdateComponent } from './update/tenant-channel-event-update.component';

export const PROVIDERS = [
  TenantChannelEventRestService,
];

@NgModule({
  imports: [
    TenantChannelEventRoutingModule,
    BsDropdownModule,
    CheckboxModule,
    CommonModule,
    FlexLayoutModule,
    FormgenModule,
    FormsModule,
    ListboxModule,
    LibrariesModule,
    PaginationModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TableModule,
    TranslateModule,
  ],
  declarations: [
    TenantChannelEventConfigurationComponent,
    TenantChannelEventFormComponent,
    TenantChannelEventListComponent,
    TenantChannelEventMessageHistoryComponent,
    TenantChannelEventTabComponent,
    TenantChannelEventUpdateComponent,
    TenantChannelEventLogWebhookComponent,
  ],
})
export class TenantChannelEventModule { }
