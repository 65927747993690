import { Injectable } from '@angular/core';

@Injectable()
export class TenantChannelService {
  currentTenantChannel: any = {};

  normalizeDoc(doc: any) {
    if (doc.license) {
      doc.credential = {
        license: doc.license
      };

      delete doc.license;
    }

    return doc;
  }
}
