import * as _ from 'lodash';
import * as Domurl from 'domurl';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { CommonService } from './common.service';

@Injectable()
export class UrlService {
  constructor(private location: Location) { }

  extractHashParts(sourceRouteUrl: string = window.location.toString()) {
    const targetUrl = new Domurl(sourceRouteUrl.replace('/#/', '/'));

    let urlOrigin = targetUrl.protocol + '://' + targetUrl.host;
    if (targetUrl.port) {
      urlOrigin += `:${targetUrl.port}`;
    }

    return {
      urlOrigin,
      urlParams: targetUrl.query,
      urlPath: targetUrl.path,
    };
  }

  objectToQueryString(obj, encodeUri: boolean = true) {
    const qs = _.reduce(obj, (result, value, key) => {
      if (!_.isNull(value) && !_.isUndefined(value)) {
        if (_.isArray(value)) {
          result += _.reduce(value, (result1, value1) => {
            if (!_.isNull(value1) && !_.isUndefined(value1)) {
              result1 += key + '=' + (encodeUri ? encodeURIComponent(value1) : value1) + '&';
              return result1;
            } else {
              return result1;
            }
          }, '');
        } else if (_.isObject(value)) {
          for (const k of Object.keys(value)) {
            const v = value[k];
            const combinedResult = `${k}=${v}`;

            result += key + '=' + (encodeUri ? encodeURIComponent(combinedResult as string) : combinedResult) + '&';
          }
        } else {
          result += key + '=' + (encodeUri ? encodeURIComponent(value as string) : value) + '&';
        }
        return result;
      } else {
        return result;
      }
    }, '').slice(0, -1);

    return qs;
  }

  back() {
    this.location.back();
  }

  openLinkNewTab(url) {
    const uniqueId = CommonService.getUniqueId();
    $('body').append(`<a id="${uniqueId}" href="${url}" target="_blank"><span></span></a>`).find(`#${uniqueId} span`).click().remove();
  }
}
