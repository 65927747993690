import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()

export class TenantChannelEventRestService {
  baseURL = `${APP_CONST.API_MAIN}/tenant-channel-events`;
  request = this._request.new(this.baseURL);

  constructor(
    private _request: RequestService,
  ) { }

  findAll(tenantChannelId: string, queryParams: any = {}) {
    return this.request.get<any>(`${tenantChannelId}`, { params: queryParams});
  }

  update(tenantChannelEventId: string, payload: any) {
    return this.request.put<any>(`${tenantChannelEventId}/update`, payload);
  }

  loadMessageTemplate(tenantChannelEventId: string, queryParams: any = {}) {
    return this.request.get<any>(`${tenantChannelEventId}/message-template`, { params: queryParams});
  }

  loadMessageTemplateCodes() {
    const baseURL = `${APP_CONST.API_MAIN}/message-template`;
    const request = this._request.new(baseURL);

    return request.get<any>(`variables`);
  }

  toggleEnabled(tenantChannelEventId: string, enabled: boolean) {
    return this.request.post<any>(`${tenantChannelEventId}/toggle-enabled/${enabled}`);
  }
}
