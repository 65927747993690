import { NgModule } from '@angular/core';
import { BasicLayoutModule } from './basic/basic-layout.module';

@NgModule({
  imports: [
    BasicLayoutModule,
  ],
  exports: [
    BasicLayoutModule,
  ]
})
export class LayoutsModule { }
