import {
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import { AuthenticationService } from '../../../libraries/auth/authentication.service';
import { BaseCreateBComponent } from '../../../libraries/base/create/base-create.bcomponent';
import { TenantChannelRestService } from '../tenant-channel-rest.service';
import { TenantChannelService } from '../tenant-channel.service';

@Component({
  selector: 'app-tenant-channel-create',
  templateUrl: 'tenant-channel-create.component.html'
})

export class TenantChannelCreateComponent extends BaseCreateBComponent {
  @Output() success: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private _authentication: AuthenticationService,
    private _tenantChannel: TenantChannelService,
    private _tenantChannelRest: TenantChannelRestService
  ) {
    super();
    this.componentId = 'TenantChannelCreateComponent';
    this.routeURL = '/tenant-channels';
    this.entrySuccessI18n = 'Channel berhasil ditambahkan';
    this.headerTitle = 'Tambah Channel';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'save',
      handle: () => {
        const formValue = this._tenantChannel.normalizeDoc(this.form.value);

        return this._tenantChannelRest.create(this._authentication.user.tenantId, formValue);
      },
      success: () => {
        this.success.emit(true);
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true,
    });

    this.registerHook({
      hookName: 'init',
      handle: () => this.page.compHookService.callMultipleHooks([
        'TenantChannelFormComponent:workflowLoadRelated',
        'TenantChannelFormComponent:workflowBuildForm'
      ])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }
}
