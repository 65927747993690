import { Component } from '@angular/core';
import { BaseFormBComponent } from '../../../libraries/base/form/base-form.bcomponent';
import { TenantChannelRestService } from '../tenant-channel-rest.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
@Component({
  selector: 'app-tenant-channel-form',
  templateUrl: 'tenant-channel-form.component.html'
})

export class TenantChannelFormComponent extends BaseFormBComponent {

  constructor(
    private _tenantChannelRest: TenantChannelRestService
  ) {
    super();

    this.componentId = 'TenantChannelFormComponent';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'buildForm',
      handle: () => {
        const channelOptions = this.relatedData.channels.map(channel => ({ value: channel._id, directLabel: channel.name }));
        this.formgenSchema.fields = [{
          name: 'license',
          field: 'text',
          templateConfig: {
            wrapFormGroupClass: false,
          },
          validations: {
            required: true,
          },
        }, {
            name: 'name',
            field: 'text',
            templateConfig: {
              wrapFormGroupClass: false,
            },
            validations: {
              required: true,
            },
          }, {
          name: 'channelId',
          field: 'select',
          validations: {
            required: true,
          },
          templateConfig: {
            wrapFormGroupClass: false,
            select: {
              options: channelOptions
            }
          },
        }];
      }
    });

    this.registerHook({
      hookName: 'loadRelated',
      handle: () => {
        return this._tenantChannelRest.getChannels().pipe(switchMap(channels => {
          const relatedData: any = {};
          relatedData.channels = channels.data;

          return of(relatedData);
        }));
      },
      wrapRetryableTask: true,
      wrapLoadingComponentIds: this.componentId,
      wrapErrorMessage: true,
    });
  }
}
