import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BasePageComponent } from '../../libraries/base/page/base-page.component';
import { TenantChannelEventConfigurationComponent } from './configuration/tenant-channel-event-configuration.component';
import { TenantChannelEventListComponent } from './list/tenant-channel-event-list.component';
import { TenantChannelEventLogWebhookComponent } from './log-webhook/tenant-channel-event-log-webhook.component';
import { TenantChannelEventMessageHistoryComponent } from './message-history/tenant-channel-event-message-history.component';
import { TenantChannelEventUpdateComponent } from './update/tenant-channel-event-update.component';

export const routes: Routes = [{
  path: '',
  children: [{
    path: '',
    component: BasePageComponent,
    children: [{
      path: ':tenantChannelId/events',
      component: TenantChannelEventListComponent,
      // canActivate: [AuthorizationService],
      data: {
        name: 'tenantChannelEvent.read'
      },
    }],
  }, {
    path: '',
    component: BasePageComponent,
    children: [{
      path: ':tenantChannelId/configuration',
      component: TenantChannelEventConfigurationComponent,
      // canActivate: [AuthorizationService],
      data: {
        name: 'tenantChannelEvent.read'
      },
    }],
  }, {
    path: '',
    component: BasePageComponent,
    children: [{
      path: ':tenantChannelId/history',
      component: TenantChannelEventMessageHistoryComponent,
      // canActivate: [AuthorizationService],
      data: {
        name: 'tenantChannelEvent.read'
      },
    }],
  }, {
    path: ':tenantChannelId/:id/update',
    component: BasePageComponent,
    children: [{
      path: '',
      component: TenantChannelEventUpdateComponent,
      // canActivate: [AuthorizationService],
      data: {
        name: 'tenantChannelEvent.update'
      },
    }],
  },  {
    path: '',
    component: BasePageComponent,
    children: [{
      path: ':tenantChannelId/log-webhooks',
      component: TenantChannelEventLogWebhookComponent,
      // canActivate: [AuthorizationService],
      data: {
        name: 'tenantChannelEvent.read'
      },
    }],
  },]
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class TenantChannelEventRoutingModule {}
