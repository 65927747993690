import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { FormgenModule } from '../../libraries/formgen/formgen.module';
import { LibrariesModule } from '../../libraries/libraries.module';
import { SharedModule } from '../../libraries/shared.module';
import { TenantChannelCreateComponent } from './create/tenant-channel-create.component';
import { TenantChannelFormComponent } from './form/tenant-channel-form.component';
import { TenantChannelListComponent } from './list/tenant-channel-list.component';
import { TenantChannelRestService } from './tenant-channel-rest.service';
import { TenantChannelRoutingModule } from './tenant-channel-routing.module';
import { TenantChannelService } from './tenant-channel.service';
import { TenantChannelUpdateComponent } from './update/tenant-channel-update.component';

export const PROVIDERS = [
  TenantChannelService,
  TenantChannelRestService,
];

@NgModule({
  imports: [
    TenantChannelRoutingModule,
    BsDropdownModule,
    CommonModule,
    FlexLayoutModule,
    FormgenModule,
    FormsModule,
    InputSwitchModule,
    ListboxModule,
    LibrariesModule,
    PaginationModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TableModule,
    TranslateModule,
  ],
  declarations: [
    TenantChannelCreateComponent,
    TenantChannelFormComponent,
    TenantChannelListComponent,
    TenantChannelUpdateComponent
  ],
})
export class TenantChannelModule { }
