import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()

export class TenantChannelRestService {
  baseURL = `${APP_CONST.API_MAIN}/tenant-channels`;
  request = this._request.new(this.baseURL);

  constructor(
    private _request: RequestService,
  ) { }

  create(tenantId: string, payload: any) {
    return this.request.post<any>(`${tenantId}/create`, payload);
  }

  findAll(tenantId: string, queryParams: any = {}) {
    return this.request.get<any>(`${tenantId}`, { params: queryParams});
  }

  load(tenantChannelId: string) {
    return this.request.get<any>(`find-by-id/${tenantChannelId}`);
  }

  testConnection(tenantChannelId: string) {
    return this.request.post<any>(`test-connection/${tenantChannelId}`);
  }

  testSendMessage(tenantChannelId: string, payload: any) {
    return this.request.post<any>(`test-send-message/${tenantChannelId}`, payload);
  }

  getMessageHistory(tenantChannelId: string, queryParams: any = {}) {
    return this.request.get<any>(`get-message-history/${tenantChannelId}`, { params: queryParams });
  }

  update(tenantChannelId: string, payload: any) {
    return this.request.put<any>(`${tenantChannelId}/update`, payload);
  }

  getChannels() {
    const baseURL = `${APP_CONST.API_MAIN}/channels`;
    const request = this._request.new(baseURL);

    return request.get<any>(``);
  }

  toggleEnabled(tenantChannelEventId: string, enabled: boolean) {
    return this.request.post<any>(`${tenantChannelEventId}/toggle-enabled/${enabled}`);
  }

  getLogWebhooks(tenantChannelId: string) {
    return this.request.get<any>(`${tenantChannelId}/log-webhooks`);
  }
}
