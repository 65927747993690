import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AclService } from './auth/acl.service';
import { AuthenticationService } from './auth/authentication.service';
import { AuthorizationService } from './auth/authorization.service';
import { CompAccessDirective } from './auth/comp-access.directive';
import { AutocompleteService } from './common/autocomplete.service';
import { ControlDisableDirective } from './common/control-disable.directive';
import { ControlTagDirective } from './common/control-tag.directive';
import { DataFormatService } from './common/data-format.service';
import { DateTimezoneService } from './common/date-timezone.service';
import { FormService } from './common/form.service';
import { IdleGuardService } from './common/idle-guard.service';
import { InjectorService } from './common/injector.service';
import { Nl2BrPipe } from './common/nl2br.pipe';
import { RouteStateService } from './common/route-state.service';
import { SafeUrlPipe } from './common/safe-url.pipe';
import { TextParseService } from './common/text-parse.service';
import { TranslatorService } from './common/translator.service';
import { UrlService } from './common/url.service';
import { ExportExcelService } from './export/excel/export-excel.service';
import { ExportDataTableService } from './export/export-data-table.service';
import { ExportDataService } from './export/export-data.service';
import { ExportPDFTemplate1Service } from './export/pdf/export-pdf-template1.service';
import { ExportPDFService } from './export/pdf/export-pdf.service';
import { FieldErrorModule } from './field-error/field-error.module';
import { FileSecurityModule } from './file/file-security.module';
import { FileService } from './file/file.service';
import { GridTableColumnModule } from './grid-table/column/grid-table-column.module';
import { GridTableFilterService } from './grid-table/grid-table-filter.service';
import { GridTableLoadingService } from './grid-table/grid-table-loading.service';
import { GridTableToggleService } from './grid-table/grid-table-toggle.service';
import { GridTableService } from './grid-table/grid-table.service';
import { ApiBootstrapService } from './http/api-bootstrap.service';
import { ApiFileRestService } from './http/api-file-rest.service';
import { HttpExtsrvService } from './http/http-extsrv.service';
import { HttpFileService } from './http/http-file.service';
import { HttpHelperService } from './http/http-helper.service';
import { RequestService } from './http/request.service';
import { TokenValidatorService } from './http/token-validator.service';
import { ImportExcelService } from './import/excel/import-excel.service';
import { ImportDataService } from './import/import-data.service';
import { BroadcastChannelService } from './interaction/broadcast-channel.service';
import { EventService } from './interaction/event.service';
import { AccountingService } from './math/accounting.service';
import { CrossMessageService } from './system-message/cross-message.service';
import { LogService } from './system-message/log.service';
import { MessageParserService } from './system-message/message-parser.service';
import { SystemMessageService } from './system-message/system-message.service';

@NgModule({
  imports: [
    CommonModule,
    FileSecurityModule,
    GridTableColumnModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    RouterModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    CompAccessDirective,
    ControlDisableDirective,
    ControlTagDirective,
    Nl2BrPipe,
    SafeUrlPipe,
  ],
  exports: [
    CompAccessDirective,
    ControlDisableDirective,
    ControlTagDirective,
    FieldErrorModule,
    FileSecurityModule,
    GridTableColumnModule,
    Nl2BrPipe,
    SafeUrlPipe,
  ]
})
export class LibrariesModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LibrariesModule,
      providers: [
        AccountingService,
        AclService,
        ApiBootstrapService,
        ApiFileRestService,
        AuthenticationService,
        AuthorizationService,
        AutocompleteService,
        CrossMessageService,
        DataFormatService,
        DateTimezoneService,
        ExportDataTableService,
        ExportExcelService,
        ExportPDFService,
        ExportPDFTemplate1Service,
        ExportDataService,
        FileService,
        FormService,
        GridTableFilterService,
        GridTableLoadingService,
        GridTableService,
        GridTableToggleService,
        HttpFileService,
        HttpExtsrvService,
        HttpHelperService,
        IdleGuardService,
        ImportDataService,
        InjectorService,
        ImportExcelService,
        LogService,
        MessageParserService,
        RequestService,
        RouteStateService,
        TextParseService,
        TokenValidatorService,
        TranslatorService,
        UrlService,
        {
          provide: 'Rollbar',
          useValue: (window as any).Rollbar,
        },
        {
          provide: 'GlobalEvent',
          useClass: EventService,
        },
        {
          provide: 'GlobalSystemMessage',
          useClass: SystemMessageService,
        },
      ],
    };
  }

  constructor(
    _injector: InjectorService,
    _authentication: AuthenticationService,
    _authorization: AuthorizationService,
    _broadcastChannelService: BroadcastChannelService,
    _crossMessage: CrossMessageService,
    _routeState: RouteStateService,
  ) { }
}

export * from './auth/authentication.service';
export * from './auth/authorization.service';
export * from './common/autocomplete.service';
export * from './common/common.service';
export * from './common/data-format.service';
export * from './common/date-timezone.service';
export * from './common/form.service';
export * from './common/idle-guard.service';
export * from './common/injector.service';
export * from './common/route-state.service';
export * from './common/text-parse.service';
export * from './common/url.service';
export * from './export/excel/export-excel.service';
export * from './export/export-data-table.service';
export * from './export/export-data.service';
export * from './export/pdf/export-pdf-template1.service';
export * from './export/pdf/export-pdf.service';
export * from './grid-table/grid-table-config.model';
export * from './grid-table/grid-table-filter.service';
export * from './grid-table/grid-table-toggle.service';
export * from './grid-table/grid-table.service';
export * from './http/api-bootstrap.service';
export * from './http/api-query-option.model';
export * from './http/http-helper.service';
export * from './http/request.service';
export * from './import/excel/import-excel.service';
export * from './import/import-data.service';
export * from './interaction/event.service';
export * from './math/accounting.service';
export * from './system-message/cross-message.service';
export * from './system-message/log.service';
export * from './system-message/message-parser.service';
export * from './system-message/system-message.model';