import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RetryDialogModule } from '../retry-dialog/retry-dialog.module';
import { AppBootstrapComponent } from './app-bootstrap.component';

@NgModule({
  imports: [
    CommonModule,
    RetryDialogModule,
  ],
  declarations: [
    AppBootstrapComponent,
  ],
  exports: [
    AppBootstrapComponent,
  ],
})
export class AppBootstrapModule { }
