
import { of as observableOf, zip as observableZip, Observable } from 'rxjs';

import { switchMapTo, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class TranslateLoaderService {
  private registeredTranslations: string[] = [];

  constructor(
    private httpClient: HttpClient,
  ) {
    this.setup();
  }

  setup() {
    this.registerTranslations([]);
  }

  registerTranslations(translationIds: string | string[]) {
    const arrayOfTranslationIds = _.castArray(translationIds);
    arrayOfTranslationIds.forEach(translationId => {
      if (!this.registeredTranslations.includes(translationId)) {
        this.registeredTranslations.push(translationId);
      }
    });
  }

  load(language: string) {
    const translations = {};

    const observables = [];
    observables.push(
      this.httpClient.get(`assets/i18n/${language}/${language}.json`).pipe(
        tap((response) => {
          _.merge(translations, response);
        }))
    );

    if (this.registeredTranslations.length) {
      this.registeredTranslations.forEach(translationId => {
        observables.push(
          this.httpClient.get(`assets/i18n/${language}/${language}-${translationId}.json`).pipe(
            tap((response) => {
              _.merge(translations, response);
            }))
        );
      });
    }

    return observableZip(...observables).pipe(
      switchMapTo(observableOf(translations)));
  }
}
