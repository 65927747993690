import { Inject, Injectable, NgZone } from '@angular/core';
import { Headers } from '@angular/http';
import { Router } from '@angular/router';
import Axios, { AxiosInstance, AxiosPromise, AxiosResponse, AxiosStatic } from 'axios';
import * as _ from 'lodash';
import { LocalStorage } from 'ngx-webstorage';
import { BehaviorSubject, Observable, throwError, from, of } from 'rxjs';
import { APP_CONST } from '../../app.const';
import { AppEvent, EventService } from '../interaction/event.service';
import { UrlService } from '../common/url.service';
import { CentralHookService } from '../interaction/central-hook.service';
import { AclService } from './acl.service';
import { switchMap, catchError, tap, map } from 'rxjs/operators';

export class MCredentialLocal {
  username: string;
  password: string;
}

export class MUser {
  token: string;
  tenantId: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  @LocalStorage() private currentUser: MUser;

  user$: BehaviorSubject<MUser> = new BehaviorSubject(null);
  clientId = 'clodeo-notif-internal';
  clientSecret = 'S!C3P4T';
  maxRefreshTokenRetry: number = 2;
  refreshTokenRunning = false;
  refreshTokenObservable: Observable<string>;

  axios: AxiosInstance = Axios.create({ baseURL: APP_CONST.API_ACCOUNT });

  private loginDestinations = {
    admin: '/news',
  };

  constructor(
    @Inject('GlobalEvent') private _globalEvent: EventService,
    private _aclService: AclService,
    private _centralHookService: CentralHookService,
    private _ngZone: NgZone,
    private _router: Router,
    private _urlService: UrlService,
  ) {
    if (this.currentUser) {
      this.user$.next(this.currentUser);
    } else {
      this.reset();
    }

    this._centralHookService.registerHook({
      hookId: 'processLoginData',
      handle: this.centralHookHandleProcessLoginData.bind(this),
    });

    this._centralHookService.registerHook({
      hookId: 'refreshToken',
      handle: this.centralHookHandleRefreshToken.bind(this),
      runCentralized: true,
    });

    this._centralHookService.registerHook({
      hookId: 'logout',
      handle: this.centralHookHandleLogout.bind(this),
    });
  }

  set user(userObject: MUser) {
    this.currentUser = userObject;

    this.user$.next(userObject);
  }

  set userAccessToken(accessToken: string) {
    if (this.currentUser) {
      this.currentUser.token = accessToken;

      this.user$.next(this.currentUser);
    }
  }

  get user() {
    return this.currentUser;
  }

  /**
   * Set authentication headers needed by Restangular
   */
  setAuthHeader(headers: any): any {
    const token = this.user.token;
    if (headers instanceof Headers) {
      headers.delete('Authorization');
      headers.append('Authorization', 'Bearer ' + token);
    } else {
      headers['Authorization'] = 'Bearer ' + token;
    }
    return headers;
  }

  axiosInterceptors(axios: AxiosInstance | AxiosStatic, interceptRequest: boolean = true, interceptResponse: boolean = true) {
    axios.interceptors.request.use(request => {
      request.headers.common['Timezone-Offset'] = (new Date()).getTimezoneOffset();
      request.headers.common['Accept-Language'] = 'id-ID';

      return request;
    });

    if (interceptRequest) {
      axios.interceptors.request.use(request => {
        if (this.user) {
          request.headers.common['Authorization'] = `Bearer ${this.user.token}`;
        }
        return request;
      });
    }

    if (interceptResponse) {
      axios.interceptors.response.use(response => response, async error => {
        const response = error.response;
        if (
          response &&
          response.status === 401 &&
          response.config &&
          response.config.headers &&
          response.config.headers['Authorization'] &&
          (
            !response.config.refreshTokenRetryCount ||
            response.config.refreshTokenRetryCount < this.maxRefreshTokenRetry
          )
        ) {

          try {
            return this.refreshToken().pipe(
              switchMap(function (accessToken) {
                this.userAccessToken = accessToken;
                response.config.headers['Authorization'] = `Bearer ${accessToken}`;
                return axios.request(response.config);
            })).toPromise();
          } catch (errRefreshToken) {
            const retryCount = (response.config.refreshTokenRetryCount || 0);
            if (retryCount < this.maxRefreshTokenRetry - 1) {
              response.config.refreshTokenRetryCount = retryCount + 1;
              return axios.request(response.config);
            } else {
              const urlCheck = this._router.url.substr(0, 6);
              if (urlCheck !== '/login') {
                this._router.navigate(['/login'], { queryParams: { tokenExpired: 'true', destination: this._router.url } });
              }
              throw errRefreshToken;
            }
          }
        }
        throw error;
      });
    }
  }

  centralHookHandleProcessLoginData(event: AppEvent) {
    const { loginData } = event.data;

    this.user = this.extractUserData(loginData);
  }

  centralHookHandleRefreshToken(event: AppEvent) {
    if (!this.user) {
      return throwError(new Error('User is not logged in.'));
    }

    const tokenUrl = APP_CONST.API_ACCOUNT + '/token';

    const data = {};

    this.refreshTokenRunning = true;

    return from(
      this.axios.post(tokenUrl, data) as AxiosPromise
    ).pipe(
      catchError(error => {
        this.refreshTokenRunning = false;

        const urlCheck = this._router.url.substr(0, 6);
        if (urlCheck !== '/login') {
          this._router.navigate(['/login'], { queryParams: { tokenExpired: 'true', destination: this._router.url } });
        }

        return throwError(error);
    }), switchMap(response => of(response.data)), tap(response => {
      this.refreshTokenRunning = false;
      this.attachRole(response);
      this.user = this.extractUserData(response);

      this._globalEvent.emit('CORE:AUTHENTICATION:TOKENCHANGED', response.access_token);

    }), switchMap(response => of(response.access_token)));
  }

  centralHookHandleLogout() {
    this.reset();

    if (this._router.url.indexOf('/login') < 0) {
      this._ngZone.run(() => {
        this._router.navigate(['/login'], {
          queryParams: {
            fromLogout: true,
          },
          replaceUrl: true,
        });
      });
    }

    this._globalEvent.emit('CORE:LOGOUT:SUCCESS');
  }

  logout() {
    this._centralHookService.callHookSafeDirectly('logout');
  }

  /**
   * Refresh current token if current token isn't available anymore for authentication.
   */
  refreshToken(companyId: string = null): Observable<string> {
    if (!this.refreshTokenRunning) {
      return this._centralHookService.callHookSafe('refreshToken', { companyId });
    }

    return this._centralHookService.onHookSuccessById('refreshToken').pipe(switchMap(success => of(success.result)));
  }

  checkPassword(password: string): Observable<any> {
    const axios = Axios.create({
      baseURL: `${APP_CONST.API_ACCOUNT}/users/me/check-password`,
    });
    this.axiosInterceptors(axios);

    return from(
      axios.request({
        url: '',
        method: 'post',
        data: `"${password}"`,
        headers: {
          'Content-Type': 'application/json'
        }
      }) as Promise<AxiosResponse>
    ).pipe(map(response => response.data));
  }

  reset() {
    this.currentUser = null;
    this.user$.next(null);
    this._aclService.flushRoles();
    this._aclService.attachRole('guest');
  }

  private attachRole(user: any) {
      user.roles = ['admin'];
  }

  extractUserData(tokenResponse: any) {
    if (tokenResponse.user) {
      const extractedData = { ...tokenResponse, ...tokenResponse.user };
      extractedData.fullName = `${extractedData.firstName} ${extractedData.lastName}`;

      delete extractedData.user;

      return extractedData;
    }

    return tokenResponse;
  }

  initialAuthentication() {
    const tokenUrl = APP_CONST.API_ACCOUNT + '/validate';
    return from(this.axios.post(tokenUrl, null, { headers: { authorization: this.user.token}}) as AxiosPromise).pipe(
      catchError(() => this._router.navigateByUrl('/error/403')),
      map(response => (response as any).data),
      tap(response => this._centralHookService.callHookSafeDirectly('processLoginData', { loginData: response })),
      switchMap(() => of(this.user))
    );
  }
}
