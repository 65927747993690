import { Component } from '@angular/core';

import { ErrorComponentService } from '../error.service';

@Component({
  selector: 'app-error-403',
  templateUrl: '403.component.html'
})
export class Error403Component {
  constructor(public error: ErrorComponentService) { }
}
