import { Component } from '@angular/core';

import { ErrorComponentService } from '../error.service';

@Component({
  selector: 'app-error-404',
  templateUrl: '404.component.html'
})
export class Error404Component {
  constructor(public error: ErrorComponentService) { }
}
