import { AppBootstrapModule } from './app-bootstrap.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppBootstrapComponent } from './app-bootstrap.component';

@NgModule({
  imports: [
    AppBootstrapModule,
    RouterModule.forChild([{
      path: 'boot',
      component: AppBootstrapComponent,
    }]),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppBootstrapRoutingModule {

}
