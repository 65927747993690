import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { Error403Component } from './403/403.component';
import { Error404Component } from './404/404.component';
import { ErrorRoutingModule } from './error-routing.module';
import { ErrorComponentService } from './error.service';

@NgModule({
  imports: [
    ErrorRoutingModule,
    CommonModule,
    FlexLayoutModule,
    TranslateModule.forChild()
  ],
  declarations: [
    Error403Component,
    Error404Component
  ],
  exports: [
    Error403Component,
    Error404Component
  ],
  providers: [
    ErrorComponentService
  ]
})
export class ErrorModule { }
