
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { AuthorizationService } from '../auth/authorization.service';
import { UrlService } from '../common/url.service';
import { ApiBootstrapService } from '../http/api-bootstrap.service';
import { TokenValidatorService } from '../http/token-validator.service';
import { RetryDialogComponent } from '../retry-dialog/retry-dialog.component';

@Component({
  selector: 'app-bootstrap',
  templateUrl: './app-bootstrap.component.html',
})
export class AppBootstrapComponent implements OnDestroy, OnInit {
  @ViewChild('retryDialog', { static: true }) elRetryDialog: RetryDialogComponent;

  bootstrapState: boolean;
  urlHash = this._url.extractHashParts();

  subscriptions: Subscription[] = [];

  bootstrapBypassRedirect: boolean;

  constructor(
    private _authentication: AuthenticationService,
    private _apiBoostrap: ApiBootstrapService,
    private _router: Router,
    private _url: UrlService,
  ) { }

  ngOnInit() {
    this.bootstrapBypassRedirect = false;

    this.subscriptions.push(
      this._apiBoostrap.change$.subscribe(state => {
        if (state && !this.bootstrapBypassRedirect) {
          this.forceNavigateRedirect();
        }
      })
    );

    if (this._authentication.user.token) {
      this.elRetryDialog.createRetryEntry(this.bootstrapApi()).subscribe();
    } else {
      this._router.navigateByUrl('/error/403');
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  bootstrapApi() {
    return Observable.create(observer => {
      this._authentication.initialAuthentication().pipe(
        tap(null, error => {
          this._apiBoostrap.removePreloader();
          observer.error(error);
        }),
        switchMap(() => this._apiBoostrap.boot()))
        .subscribe(() => {
          observer.next(true);
          observer.complete();
        });
    });
  }

  async forceNavigateRedirect() {
    let redirected = await this.navigateRedirect();
    while (!redirected) {
      redirected = await this.navigateRedirect();
    }
  }

  navigateRedirect() {
    return this._router.navigateByUrl(`
      ${this.urlHash.urlParams.sourcePath}${this.urlHash.urlParams.sourceQuery ? '?' + this.urlHash.urlParams.sourceQuery : ''}
    `.trim());
  }

  async forceNavigateLogin() {
    let redirected = await this.navigateLogin();
    while (!redirected) {
      redirected = await this.navigateLogin();
    }
  }

  navigateLogin() {
    return this._router.navigate(['/login'], {
      queryParams: {
        destination: `
          ${this.urlHash.urlParams.sourcePath}${this.urlHash.urlParams.sourceQuery ? '?' + this.urlHash.urlParams.sourceQuery : ''}
        `.trim(),
      },
    });
  }
}
