import { Component } from '@angular/core';
import swal from 'sweetalert2';
import { AuthenticationService } from '../../../libraries/auth/authentication.service';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { TenantChannelRestService } from '../tenant-channel-rest.service';

@Component({
  selector: 'app-tenant-channel-list',
  templateUrl: 'tenant-channel-list.component.html'
})

export class TenantChannelListComponent extends BaseListBComponent<any> {
  tenantChannels: any = [];
  tenantChannelEnabled: any = {};

  constructor(
    private _authentication: AuthenticationService,
    private _tenantChannelRest: TenantChannelRestService
  ) {
    super();
    this.componentId = 'TenantChannelListComponent';
    this.headerTitle = 'Channel';

    this.headerButtons = [{
      type: 'custom',
      label: 'Tambah',
      color: 'primary',
      routerLink: '/tenant-channels/create'
    }];
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: () => {
        return this._tenantChannelRest.findAll(this._authentication.user.tenantId, this.qParams);
      },
      success: (data) => {
        for (const tenantChannel of data) {
          this.tenantChannelEnabled[tenantChannel._id] = tenantChannel.enabled;
        }

        this.tenantChannels = data;
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true,
      wrapLoadingComponentIds: this.componentId
    });

    this.registerHook({
      hookName: 'toggleEnabled',
      handle: (event) => {
        const tenantChannelEventId: string = event.data;
        const enabled: boolean = !this.tenantChannelEnabled[tenantChannelEventId];

        return this._tenantChannelRest.toggleEnabled(tenantChannelEventId, enabled);
      },
      success: (data) => {
        const message: string = data.enabled ? 'Channel telah berhasil diaktifkan' : 'Channel telah berhasil dinonaktifkan';

        this.comp._globalSystemMessage.log({
          message,
          type: 'success',
          showAs: 'growl',
          showSnackBar: false
        });

        this.tenantChannelEnabled[data._id] = data.enabled;

        this.callHookDirectly('loadData');
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true,
      wrapLoadingComponentIds: this.componentId
    });

    this.registerHook({
      hookName: 'init',
      handle: () => this.page.compHookService.callMultipleHooks([
        'TenantChannelListComponent:loadData',
      ]),
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  toggleEnabled(tenantChannelEventId: string) {
    const setActive: boolean = this.tenantChannelEnabled[tenantChannelEventId];
    this.tenantChannelEnabled[tenantChannelEventId] = !this.tenantChannelEnabled[tenantChannelEventId];

    return swal.fire({
      title: setActive ? 'Aktifkan channel' : 'Nonaktifkan channel',
      text: `Anda yakin untuk ${setActive ? 'mengaktifkan' : 'menonaktifkan'} channel ini?`,
      icon: 'question',
      showCancelButton: true,
    }).then(res => {
      if (res.value) {
        return this.callHookDirectly('toggleEnabled', tenantChannelEventId);
      }
    });
  }
}
