
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { merge , Observable, Subject, Subscription, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { SpinnerService } from '../spinner/spinner.service';
import { MessageParserService } from '../system-message/message-parser.service';
import { MRetryDialogEntry, MRetryDialogEntryConfig } from './retry-dialog.model';

@Component({
  selector: 'app-retry-dialog',
  exportAs: 'app-retry-dialog',
  styleUrls: ['./retry-dialog.component.scss'],
  templateUrl: './retry-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RetryDialogComponent implements AfterViewInit {
  @ViewChild('retryButton', { static: false }) elRetryButton: ElementRef;
  @Input() parentElement: HTMLElement;

  retries: MRetryDialogEntry[] = [];
  isShown: boolean = false;

  get retriesHasOwnMessage() {
    return this.retries.filter(retry => retry.errorMessages && retry.errorMessages.length).length > 0;
  }

  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _messageParserService: MessageParserService,
    private _spinner: SpinnerService,
  ) { }

  ngAfterViewInit() {
    if (this.parentElement) {
      $(this.parentElement).css('display', 'block');
      $(this.parentElement).css('position', 'relative');
    }
  }

  createRetryEntry(targetObservable: Observable<any>, config: MRetryDialogEntryConfig = {}): Observable<any> {
    let observableIsError = false;
    return Observable.create(observer => {
      let retryAssigned = false;
      let retryEntry: MRetryDialogEntry;
      let targetObservableSubscription: Subscription;

      targetObservable = targetObservable.pipe(
        catchError(error => {
          observableIsError = true;

          if (!retryAssigned) {
            let shouldRetryNow = false;

            if (config.shouldRetry) {
              shouldRetryNow = config.shouldRetry(error);
            } else if (error.request && (
              error.request.status === 0 ||
              `${error.request.status}`.startsWith('5')
            )) {
              shouldRetryNow = true;
            }

            if (shouldRetryNow) {
              const errorMessages = this._messageParserService.parse(error) as string[];

              retryEntry = {
                maxRetry: config.maxRetry,
                onRetry: targetObservable,
                retried: 0,
                errorMessages,
                unsubscribeNotifier: new Subject(),
              };
              const retriesLength = this.retries.push(retryEntry);
              retryEntry.index = retriesLength - 1;

              retryAssigned = true;

              if (!this.isShown) {
                this.isShown = true;
                this._changeDetector.detectChanges();

                if (config.onShown) {
                  config.onShown();
                }
              }
            } else {
              observableIsError = false;
            }
          }

          return throwError(error);
        }),
        tap(success => {
          observableIsError = false;

          observer.next(success);
        }), finalize(() => {
          if (!observableIsError) {
            observer.complete();

            if (targetObservableSubscription) {
              targetObservableSubscription.unsubscribe();
            }
          }
        }));

      targetObservableSubscription = targetObservable.subscribe();

      return () => {
        targetObservableSubscription.unsubscribe();

        if (retryEntry) {
          this.disposeRetry(retryEntry.index);
          retryEntry.unsubscribeNotifier.next(true);
        }
      };
    });
  }

  retry() {
    const spinner = this._spinner.show({
      element: this.elRetryButton.nativeElement,
      loaderType: 3,
    });

    merge(
      ...this.retries.map((retry, retryIdx) => this.doRetry(retry).pipe(takeUntil(retry.unsubscribeNotifier)))
    ).pipe(finalize(() => {
      spinner.dispose();
    })).subscribe();
  }

  disposeRetry(retryIdx: number = 0) {
    this.retries.splice(retryIdx, 1);

    if (!this.retries.length) {
      this.isShown = false;
      this._changeDetector.detectChanges();
    }
  }

  private doRetry(retry: MRetryDialogEntry) {
    if (retry.maxRetry !== 0 && retry.retried === retry.maxRetry) {
      this.disposeRetry(retry.index);
      return throwError('max retry');
    } else {
      return retry.onRetry.pipe(
        tap(() => {
          this.disposeRetry(retry.index);
        }),
        finalize(() => {
          retry.retried = retry.retried + 1;
        }));
    }
  }
}
